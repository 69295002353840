import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { useData, useLoading } from '@data-provider/react';

import Layout from '@components/Layout';
import Header from '@components/Header';
import SEO from '@components/Seo';
import CookieBanner from '@components/Cookies/CookieBanner';
import MenuCardComponent from '@components/menu-card/MenuCardComponent';

import { MenuCardProps } from './@types/MenuCardProps';
import { getMenuCardPathVariables } from '@utils';
import { getMenu } from '@services/menu.service';
import { IAPIResponse } from '@models/api-response.model';
import { IMenu } from '@models/menu.model';




const SectionContainer= tw.section`p-4 pt-4 md:pt-24 flex flex-wrap flex-row items-stretch w-full`;
const ArticlesContainer= tw.div`sm:w-full max-w-6xl mx-auto pt-20 sm:pt-0 md:pt-10 z-0`;
const MenuCard : React.FC<MenuCardProps> = props => { // ({location, pageContext})

    const { location } = props;

    // console.log('MenuCard props', props);
    console.log('MenuCard location', location);
    

    const pathVariables:string[] = getMenuCardPathVariables(location.pathname);
    console.log('MenuCard path variables', pathVariables);
    const token: string = pathVariables[1];
    const menuProvider = getMenu.query({ urlParams: { token: token }});
    const response: IAPIResponse<IMenu> = useData(menuProvider);


    console.log('retrieved menu response', response);

    return (
        <Layout>
            <Header fullWidth={true}/>
            <SEO
                lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
            />            
            {response && 
            <SectionContainer id="menu-card">
                
                <MenuCardComponent menu={response.data}></MenuCardComponent>
            </SectionContainer>    
            }
            <CookieBanner/>
        </Layout>
    );
}

export default MenuCard;